<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="4"
          class="mb-2"
        >
          <h3>Name:</h3>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                placeholder="Search by name"
                @keyup.enter="defaultFilter.search = search"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="clearSearch"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  :disabled="!search"
                  @click="defaultFilter.search = search"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <data-table
      :module-name="'promosList'"
      :fields="fields"
      :filters="defaultFilter"
      sort-by="updatedAt"
      :sort-desc="true"
    >
      <template #cell(name)="data">
        <b-link :to="{ name: 'promo-detail', params: { id: data.item.id } }">
          {{ data.item.name }}
        </b-link>
      </template>

      <template #cell(active)="data">
        <b-badge
          v-if="data.item.active"
          variant="light-primary"
        >
          Active
        </b-badge>
        <b-badge
          v-else
          variant="light-danger"
        >
          Inactive
        </b-badge>
      </template>

      <template #cell(updatedAt)="data">
        {{ formatDateTime(data.item.updatedAt) }}
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>
    </data-table>
  </div>
</template>
<script>
import {
  BBadge,
  BButton, BCard, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow,
} from 'bootstrap-vue'
import DataTable from '@/components/ui/tables/DataTable.vue'
import { formatDateTime } from '@/plugins/formaters'

const fields = [
  {
    key: 'name',
    sortable: true,
  },
  {
    key: 'active',
    sortable: true,
  },
  {
    key: 'updatedAt',
    sortable: true,
  },
  {
    key: 'createdAt',
    sortable: true,
  },
]

export default {
  components: {
    BBadge,
    BRow,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BCol,
    BButton,
    BCard,
    DataTable,
    BFormInput,
    BLink,
  },
  data() {
    return {
      fields,
      defaultFilter: {
        search: '',
      },
      search: '',
    }
  },
  methods: {
    formatDateTime,
    clearSearch() {
      this.defaultFilter.search = ''
      this.search = ''
    },
  },
}
</script>
